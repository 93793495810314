import { Component, OnInit } from '@angular/core';
import { ServiceService } from '../service.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-project-details',
  templateUrl: './project-details.component.html',
  styleUrls: ['./project-details.component.css']
})
export class ProjectDetailsComponent implements OnInit {
  project = {}
  constructor(private _service : ServiceService,private activatedRoute: ActivatedRoute) {
    this.activatedRoute.queryParams.subscribe(params => {
      this._service.get('projects').subscribe(data => {
       data= data.filter(res => res.id = params['id']);
       this.project = data[0]
         });
  });
    
   }

  ngOnInit() {
  }

}
