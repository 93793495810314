import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-consultancy',
  templateUrl: './consultancy.component.html',
  styleUrls: ['./consultancy.component.css']
})
export class ConsultancyComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
