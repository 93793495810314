import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-epc-division',
  templateUrl: './epc-division.component.html',
  styleUrls: ['./epc-division.component.css']
})
export class EPCDivisionComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
