import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';


@Injectable()
export class ServiceService {
  constructor(private http: HttpClient) {
   }
   protected baseUrl = "assets/db/"
  
   get(url):Observable<any>{   
    return this.http.get(this.baseUrl+url+'.json')
  }
  
}