import { NgModule, Component } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';


import { ProjectsComponent } from './projects/projects.component';
import { SharedModule } from './shared/shared.module';
import { ContactComponent } from './contact/contact.component';


import { OutsourcingComponent } from './outsourcing/outsourcing.component';
import { GalleryComponent } from './gallery/gallery.component';
import { OverviewComponent } from './AboutUs/overview/overview.component';
import { FromFounderDeskComponent } from './AboutUs/from-founder-desk/from-founder-desk.component';
import { MeetOurTeamComponent } from './AboutUs/meet-our-team/meet-our-team.component';

import { CivilComponent } from './OurServices/civil/civil.component';
import { ElectricalComponent } from './OurServices/electrical/electrical.component';
import { EPCDivisionComponent } from './OurServices/epc-division/epc-division.component';
import { SolarComponent } from './OurServices/solar/solar.component';
import { ITServicesComponent } from './OurServices/it-services/it-services.component';
import { ConsultancyComponent } from './OurServices/consultancy/consultancy.component';

const routes: Routes = [
  {
    path:'',
    component:HomeComponent
  },
  {
    path:'home',
    component:HomeComponent
  },
 
  {
    path:'project',
    component:ProjectsComponent
  },
  {
    path:'project-details',
    component:ProjectsComponent
  },
 
  {
    path:'contact',
    component:ContactComponent
  },
 
 
  {
    path:'outsourcing',
    component:OutsourcingComponent
  },
  {
    path:'gallery',
    component:GalleryComponent
  },
  {
    path: 'aboutus',
   
    children: [
        {
            path: 'overview',
            component: OverviewComponent
        },
        {
            path: 'meet-over-team',
            component: MeetOurTeamComponent
        },
        {
          path: 'from-founder-desk',
          component: FromFounderDeskComponent
      },
     
    ]
},
{
  path: 'OurServices',
 
  children: [
      {
          path: 'civil',
          component: CivilComponent
      },
      {
          path: 'electrical',
          component: ElectricalComponent
      },
      {
        path: 'consultancy',
        component: ConsultancyComponent
    },
      {
        path: 'epc-division',
        component: EPCDivisionComponent
    },
    {
      path: 'it-services',
      component: ITServicesComponent
  },
    {
      path: 'solar',
      component: SolarComponent
  }
  ]
}
  
];

@NgModule({
  imports: [RouterModule.forRoot(routes,{ useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
