import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  constructor(private _router : Router) { }

  goToTop(link){
    this._router.navigateByUrl('/home');

  }
  ngOnInit() {
  }
  collapsed = true;
  
  opened = false;
  openClose(){
    this.opened = !this.opened
  }
  openProject(i){
    this._router.navigate(['/project'], { queryParams: { type: i } });
    this.opened = !this.opened
  }
  openService(i){
    this._router.navigate(['/services'], { queryParams: { type: i } }); 
  }

 
  
  openPage(page){
    this._router.navigate([page])
    this.opened = !this.opened
  }

}
