import { Injectable } from '@angular/core';
import { Http, Response } from '@angular/http';
import { Observable } from 'rxjs';
import 'rxjs/Rx';

@Injectable({
  providedIn: 'root'
})
export class ContactService {
  baseUrl = ''
  url = "sendemail.php";
  results:any ;

  emailResponse:any;
  constructor(private http:Http) { 
    this.http.get('/baseUrl.json').subscribe(data => {
      this.baseUrl = data.json().url;
    });
  }
  
  addBookWithObservable(name,email,subject,phone,message) {
    // let headers = new Headers();
    // headers.append('Content-Type', 'application/json');
    // headers.append('Authorization', 'Bearer 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');
    // let options = new RequestOptions({ headers: headers });
    
     this.http.get(this.results.json().url+this.url+'name='+name+'&text='+message+'&email='+email+'&subject='+subject+'&phone='+phone).subscribe(data => {
      this.emailResponse = data.json();
      if(this.emailResponse.status_code=='500'){
      }
    });

  }
  sendMail(name,email,subject,phone,message) : Observable<any>{

    return this.http.get(this.baseUrl+this.url+'?'+
    'name='+name+'&text='+message+'&email='+email+'&subject='+subject+'&phone='+phone)
    .map(
      (response: Response) => {
        return response.json();
      }
    )
    .catch((error : Response) => {
      return Observable.throwError(error.json())
    })
  }
  private extractData(res: Response) {
    let body = res.json();
          return body || {};
    }

}
