import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ServiceService } from '../service.service';

@Component({
  selector: 'app-projects',
  templateUrl: './projects.component.html',
  styleUrls: ['./projects.component.css']
})
export class ProjectsComponent implements OnInit {
  projectTitle = ''
  
  constructor(private _router : Router, private _service : ServiceService, private activatedRoute : ActivatedRoute) { 
    this.activatedRoute.queryParams.subscribe(params => {
      if( params['type']=='Ongoing_Projects'){
        this.projectTitle = 'Products'
        this._service.get('Ongoing_Projects').subscribe(data => {  
          this.projects = data
          
          
        });
      }
     
     
      else{
        this.projectTitle = 'Products'
        this._service.get('Completed_Projects').subscribe(data => {  
          this.projects = data;
         
        });

      }
  });
    
  }
  projects = []
  ngOnInit() {
  }
  openProjectDeatils(i){
    this._router.navigate(['/project-deatils'], { queryParams: { id: i } });
  }
}
