import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ProjectsComponent } from './projects/projects.component';
import { ContactComponent } from './contact/contact.component';
import { SharedModule } from './shared/shared.module';
import { ProjectDetailsComponent } from './project-details/project-details.component';
import { HttpClientModule } from '@angular/common/http';
import { HttpModule } from '@angular/http';
import { ServiceService } from './service.service';

import { OutsourcingComponent } from './outsourcing/outsourcing.component';
import { GalleryComponent } from './gallery/gallery.component';
import { FromFounderDeskComponent } from './AboutUs/from-founder-desk/from-founder-desk.component';
import { OverviewComponent } from './AboutUs/overview/overview.component';
import { MeetOurTeamComponent } from './AboutUs/meet-our-team/meet-our-team.component';

import { CivilComponent } from './OurServices/civil/civil.component';
import { ElectricalComponent } from './OurServices/electrical/electrical.component';
import { EPCDivisionComponent } from './OurServices/epc-division/epc-division.component';
import { SolarComponent } from './OurServices/solar/solar.component';
import { ITServicesComponent } from './OurServices/it-services/it-services.component';
import { ConsultancyComponent } from './OurServices/consultancy/consultancy.component';



@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    ProjectDetailsComponent,
   
    ProjectsComponent,
    ContactComponent,
 
   
    OutsourcingComponent,
    GalleryComponent,
    FromFounderDeskComponent,
    OverviewComponent,
    MeetOurTeamComponent,
 
    CivilComponent,
    ElectricalComponent,
    EPCDivisionComponent,
    SolarComponent,
    ITServicesComponent,
    ConsultancyComponent,
    
  ],
  imports: [
    BrowserModule,
    FormsModule,
    AppRoutingModule,
    SharedModule,
    HttpClientModule,
    HttpModule
  ],
  providers: [ServiceService,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
