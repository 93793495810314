import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-from-founder-desk',
  templateUrl: './from-founder-desk.component.html',
  styleUrls: ['./from-founder-desk.component.css']
})
export class FromFounderDeskComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
