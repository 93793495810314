import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { RouterModule } from '@angular/router';
import { ClientsComponent } from './clients/clients.component';

@NgModule({
  declarations: [HeaderComponent, FooterComponent, ClientsComponent],
  imports: [
    CommonModule,RouterModule
  ],
  exports:[HeaderComponent, FooterComponent,ClientsComponent],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
],
})
export class SharedModule { }
