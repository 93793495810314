import { Component, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { ContactService } from './contact.service';
import { Headers, RequestOptions, Http } from '@angular/http';

import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent  implements OnInit , OnChanges {

  requestSumbitted = false
  requestError = false
  constructor( private router : Router, private http : Http,
  private contactService : ContactService) { }

  errorMessage;
  content = {
    'name' : '',
    'email' : '',
    'text' : '',
    'subject' : ''
  }

  onSubmit(f: NgForm) {  
    console.log(f)
    this.contactService.sendMail(f.value.name,f.value.email,f.value.subject,f.value.phone,f.value.message)
    .subscribe( book => {
      this.requestSumbitted = true 
      console.log(book)

      this.content = {
        'name' : '',
        'email' : '',
        'text' : '',
        'subject' : ''      }
    },
          error => {
            this.requestSumbitted = true 
            f.reset()
          });
  }
  ngOnInit() {};
  ngOnChanges(changes: SimpleChanges) {
    this.requestSumbitted = false
    this.requestError = false
  }


}
